<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<Search
			v-if="search"
			class="mt-10 box"
			:search="search"
			:option="search_option"
			:user="user"

			@reset="reset"
			@click="getSearch(1)"
			@toItem="toItem"
			@setSearchDateType="setSearchDateType"
			@agency="setAgency"
		>
			<select
				v-if="is_master"
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.viewGroup"
				@change="getSearch(1)"
			>
				<option value="">구분</option>
				<option
					v-for="(add, index) in viewGroup"
					:key="'viewGroup_' + index"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
		</Search>

		<div class="mt-10 pa-10 bg-white flex-column overflow-y-auto">

			<div class="justify-space-between">

				<div class="line-height-200">총 <span class="font-weight-bold">{{ search.total_count | makeComma }}</span> 건</div>
				<select
					class="pa-5-10 mr-10 size-px-12"
					v-model="search.size"
					@change="getSearch(1)"
				>
					<option
						v-for="cnt in codes.list_cnt"
						:key="'cnt_' + cnt"
						:value="cnt"
					>{{ cnt }} 건씩 보기</option>
				</select>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>
					<col width="60px" />
					<col width="100px" />
					<col width="100px" v-if="is_master" />
					<col width="80px" v-if="is_master" />
					<col width="auto" />
					<template
						v-if="is_master"
					>
					<col width="80px" />
					<col width="80px" />

					<col width="80px" />
					</template>
					<col width="120px" />
					<col width="120px" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>등록일</th>
					<th v-if="is_master">구분</th>
					<th v-if="is_master">전달</th>
					<th>제목</th>
					<template
						v-if="is_master"
					>
					<th>상단고정</th>
					<th>노출여부</th>

					<th>조회수</th>
					</template>
					<th>작성자</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{ (10 * (search.page ? search.page - 1 : '0')) + index + 1 }}</td>
					<td>{{ item.createAt.split('T')[0].replaceAll('-','.') }}</td>
					<td v-if="is_master">{{ item.viewGroupName }}</td>
					<td v-if="is_master">{{ item.viewGroup === 'SELECT' ? item.selectCount : '-' }}</td>
					<td class="text-left">{{ item.title }}</td>

					<template
						v-if="is_master"
					>
					<td>{{ item.is_notice }}</td>
					<td>{{ item.is_use }}</td>

					<td>{{ item.view }}</td>
					</template>
					<td>{{ item.author }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

            <div
                v-else
                class="full-height flex-column justify-center top-line-identify mt-10"
            >
				<div class="text-center pa-20">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>
		</div>

        <Pagination
            :program="program"
            :align="'center'"
            :options="search"

            class="mt-auto"
            @click="getSearch"
        ></Pagination>

	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";

export default {
	name: 'NoticeList',
	components: { Search, Pagination},
	data: function(){
		return {
            user: [],
			program: {
				name: '페이스터 공지사항'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,keyword: this.$route.query.keyword ? this.$route.query.keyword : ''
				,viewGroup: this.$route.query.viewGroup ? this.$route.query.viewGroup : ''
				,branchWriter: ''
				,sDate: ''
				,eDate: ''
				,search_date_type: 'month'
                ,total_count: 0
			}
			,search_option: {
				search_type: [
					{ name: '제목+내용', column: 0}
					, { name: '제목', column: 1}
					, { name: '내용', column: 2}
					, { name: '지사명', column: 3}
				]
				,is_item: false
				,is_search_date_type: true
				,sDate: true
				,eDate: true
			}
			,items: []
			,item: {}
			,viewGroup: [
                { name: '전체', code: 'ALL' },
                { name: '총판', code: 'BRANCH' },
                { name: '지사', code: 'DISTRIBUTOR' },
                { name: '대리점', code: 'AGENCY' },
                { name: '딜러', code: 'RESELLER' },
                { name: '가맹점', code: 'SHOP' },
                { name: '선택', code: 'SELECT' },
            ]
			,items_type: []
		}
	}
	,computed: {
		item_list: function(){
            let self = this
			return this.items.filter(function(item){
				if(item.isFixed == '0'){
					item.is_notice = '-'
				}else{
					item.is_notice = '고정'
				}

				if(item.exposure == '0'){
					item.is_use = '-'
				}else{
					item.is_use = '노출'
				}

                for (let i = 0; i < self.viewGroup.length; i++) {
                    if (item.viewGroup === self.viewGroup[i].code) {
                        item.viewGroupName = self.viewGroup[i].name
                        break
                    }
                }

				return item
			})
		}
		,is_master: function(){
			let is = false

			if(this.user.roleGroup == 'admin'){
				is = true
			}

			return is
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$layout.onLoading()
                if(this.search.search_type) {
                    this.$set(this.search, 'keywordType', this.search.search_type)
                }
				let result = await this.$Axios({
					method: 'get'
					, url: 'notice/' + (this.$route.name == 'NoticeList' ? 'admin' : 'branch')
					, data: this.search
				})

				if(result.success){
					this.items = result.data.data
					this.search.total_count = result.data.totalCount
					localStorage.setItem('query', JSON.stringify(this.search))
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,toDetail: function(item){
            this.$layout.push( { name: 'NoticeDetail', params: { idx: item.idx }, not_query: true})
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			this.getData()
		}
		,toItem: function(){
			this.$layout.push( { name: 'NoticeItem', not_query: true})
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,search_type: ''
				,keyword: ''
				,viewGroup: 0
			}
			this.getData()
		}
		,setSearchDateType: function(type){
			this.search.search_date_type = type
		}
		,setAgency: function(agency){
			this.search.branchIdx = this.user.branchIdx ? this.user.branchIdx : agency.branchList
			this.search.distributorIdx = this.user.distributorIdx ? this.user.distributorIdx : agency.distributorList
			this.search.agencyIdx = this.user.agencyIdx ? this.user.agencyIdx : agency.agencyList
			this.search.resellerIdx = this.user.resellerIdx ? this.user.resellerIdx : agency.resellerList
			this.getSearch(this.search.page)
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
        this.search.branchWriter = this.$route.query.branchWriter ? this.$route.query.branchWriter : this.user.roleGroup == 'branch' ? '1' : '0'
        this.search_option.is_item = (this.$route.name == 'NoticeList' && this.user.roleGroup == 'admin') || (this.$route.name == 'NoticeBranch' && this.user.roleCode == '1') ? true : false
		this.getData()
	}
	,watch: {

		'search.search_date_type': {
			immediate: true
			, handler: function(call){
                if (!this.$layout) {
                    this.$layout = this.$root.$children[0].$children[0]
                    this.user = this.$layout.user
                }
				let today = this.date.getToday('-')
				this.search.eDate = today
				switch(call){
					case 'today':
						this.search.sDate = today

						this.getData()
						break
					case 'weekly':
						this.search.sDate = this.date.getLastDate(today, 7, '-')

						this.getData()
						break
					case 'month':
						this.search.sDate = this.date.getLastDate(today, 30, '-')

						this.getData()
						break
					case '3':
						this.search.sDate = this.date.getLastDate(today, 90, '-')
						break
					case '6':
						this.search.sDate = this.date.getLastDate(today, 180, '-')
						break
				}
			}
		}
	}
}
</script>